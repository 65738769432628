  <template>
  <div class="detail">
    <div class="detail_content">
      <header class="flex w1200 marAuto0">
        <div class="banner">
          <el-carousel indicator-position="none" height="350">
            <el-carousel-item v-for="(n,i) in detailData.promotionalGra" :key="i">
              <img :src="n" alt />
            </el-carousel-item>
          </el-carousel>
          <div class="bottom_tab relative">
            <div class="left cursor el-icon-arrow-left"></div>
            <div class="right cursor el-icon-arrow-right"></div>
            <ul class="list flex">
              <li v-for="(n,i) in detailData.promotionalGra" :key="i">
                <img :src="n" alt class="err_image" />
              </li>
            </ul>
          </div>
        </div>
        <div class="info width100">
          <h1>{{detailData.productName}}</h1>
          <div class="money">
            <span class="color99 name">价格</span>
            <span class="fc">￥</span>
            {{detailData.productPrice||'面议'}}<small class="marL10">{{detailData.measure}}</small>
          </div>
          <div class="tip">
            <span class="color99 name">交易方式</span>
            商城仅支持线下交易，请联系订购并留下您的联系方式，商家收到后会与您联系，完成交易。
          </div>
          <button class="bs_btn contact" @click="to_order">联系订购</button>
          <div class="tips">
            <span>
              <img src="@/static/fjweb/disclaimer.png" alt class="block_inline img" /> 免责声明
            </span>
            <div class="block_inline">
              平台仅负责产品的展示推广，线下交易请谨慎辨别参与方信息的真实与有效性，避免上当受骗。
              线下交易因退货、换货、价格、质量等方面引发的问题与本平台无关。请自行在交易前达成协
              议，以免给您带来不必要的麻烦。
            </div>
          </div>
        </div>
      </header>

      <section class="w1200 marAuto0">
        <ul class="tab flex">
          <li v-for="(n,i) in tab" :key="i" :class="{'active':active == i}" @click="tabs(i)">{{n}}</li>
        </ul>
        <div class="content" v-if="active == 0" v-html="detailData.productDetails"></div>
        <div class="content" v-if="active == 1" v-html="detailData.paramAttr"></div>
        <div class="content" v-if="active == 2" v-html="detailData.specialNote"></div>
        <div class="content" v-if="active == 3" v-html="detailData.afterSale"></div>
      </section>
    </div>

    <el-dialog
      class="dialog"
      v-if="dialogVisible"
      title="联系订购"
      :visible.sync="dialogVisible"
      width="880px"
    >
      <div class="dialog_content">
        <div class="tip">订购声明：目前商城为了安全起见，暂不支持在线支付，订购采取线上发送订单请求方式进行。</div>
        <div class="dialog_header flex">
          <div class="banner">
            <img :src="detailData.productImage" alt />
          </div>
          <div class="info">
            <span>订购信息：</span>
            <h1>{{detailData.productName}}</h1>
          </div>
        </div>
        <el-form :model="form" :rules="rules" ref="form" label-width="100px" class="demo-ruleForm">
          <el-form-item label="订购数量">
            <el-input-number v-model="form.productNums" :min="1"></el-input-number>
          </el-form-item>
          <el-form-item label="订购人姓名" prop="orderName">
            <el-input v-model="form.orderName" placeholder="请输入您的姓名" class="w430"></el-input>
          </el-form-item>
          <el-form-item label="订购人电话" prop="orderTel">
            <el-input v-model="form.orderTel" placeholder="请输入您的联系方式" class="w430"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="orderProvince">
            <ThreeLink
              class="w430 marB10"
              :codeType="'provinceid'"
              :codeLevel="'-1'"
              @setMValue="setScasValue($event,form,['orderProvince','orderCity','orderThreeCity'])"
            ></ThreeLink>
          </el-form-item>
          <el-form-item label="详细地址" prop="orderAddr">
            <el-input v-model="form.orderAddr" placeholder="请输入详细地址" class="w430 transformY-10"></el-input>
          </el-form-item>
        </el-form>
        <div class="form_btn text_C">
          <button class="bs_btn" @click="submitForm('form')">联系订购</button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ThreeLink from "components/selectComponents/ThreeLink";
export default {
  data() {
    return {
      active: 0,
      tab: ["商品详情", "参数属性", "特别说明", "售后保障"],
      detailData: [],
      detailData_img: [],
      url: {
        queryById: "/jjProducts/queryById",
        add: "/productOrder/add",
      },
      dialogVisible: false,
      form: {
        productId: "",
        orderName:"",
        orderTel: "",
        productNums: "",
        orderProvince: "",
        orderCity: "",
        orderThreeCity: "",
        orderAddr: "",
      },
      rules: {
        orderName: [
          { required: true, message: "收货人姓名为必填项", trigger: "blur" },
          { min: 1, max: 20, message: "输入不规范", trigger: "blur" },
        ],
        orderTel: [
          { required: true, message: "收货人电话为必填项", trigger: "blur" },
          { min: 11, max: 11, message: "输入不规范", trigger: "blur" },
        ],
        orderProvince: [
          { required: true, message: "收货人地址为必填项", trigger: "blur" },
        ],
        orderAddr: [
          { required: true, message: "详细地址为必填项", trigger: "blur" },
        ],
      },
    };
  },
  components: {
    ThreeLink,
  },
  methods: {
    async queryById(id) {
      try {
        let res = await this.$axios.get('/api/app-jycy-gansulz/product/details?id='+id );
        console.log(res, "edwq");
        if (res.data.success) {
          this.detailData = res.data.data;
          if (this.detailData.promotionalGra){
            this.detailData.promotionalGra = JSON.parse(this.detailData.promotionalGra);
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    setScasValue(event, form, keys) {
      console.log(event);
      console.log(keys);
      for (let i = 0; i < keys.length; i++) {
        let value = event[i];
        if (!value) {
          value = "";
        }
        let key = keys[i];
        form[key] = value;
      }
    },
    to_order() {
      this.dialogVisible = true;
    },
    submitForm(formName) {
      let that = this;
      that.form.productId = that.$route.query.id;
      that.form.storeId = that.detailData.storeId;
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.$axios.post('/api/app-jycy-hebei/product/saveOrder',that.form).then((result) => {
            if (result.data.success) {
              that.$message({
                type: "success",
                center: true,
                message: "添加订单成功!",
              });
              that.dialogVisible = false;
              that.form = {};
            } else {
              that.$message({
                type: "fail",
                center: true,
                message: "新增订单失败!",
              });
            }
          });
        }
      });
    },

    tabs(i) {
      this.active = i;
    },
  },
  created() {
    this.queryById(this.$route.query.id);
  },
};
</script>

  <style scoped lang="less">
@import "./css/shopping.less";
</style>
